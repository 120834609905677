import _ from 'lodash';
import { type ControllerFlowAPI } from '@wix/yoshi-flow-editor';

type ErrorMonitorOptions = Parameters<
  ControllerFlowAPI['errorMonitor']['captureException']
>[1];

type Options = {
  transaction?: string;
} & ErrorMonitorOptions;

export function monitor(api: ControllerFlowAPI) {
  const { errorMonitor } = api;
  const { wixCodeApi, platformAPIs } = api.controllerConfig;

  const msid = platformAPIs?.bi?.metaSiteId;
  const user = {
    id: wixCodeApi.user.currentUser.id,
    role: wixCodeApi.user.currentUser.role,
    loggedIn: wixCodeApi.user.currentUser.loggedIn,
  };

  return {
    captureMessage(message: string, options?: Options) {
      return errorMonitor.captureMessage(
        message,
        _.merge(options, {
          tags: { user, msid, url: getCurrentUrl() },
        }),
      );
    },
    captureException(exception: Error, options?: Options) {
      errorMonitor.captureException(
        exception,
        _.merge(options, {
          tags: { user, msid, url: getCurrentUrl() },
        }),
      );
    },
  };

  function getCurrentUrl() {
    const { baseUrl, path } = wixCodeApi.location;
    const url = new URL(path.join('/'), baseUrl);

    return url.toString();
  }
}
